import React from 'react'

import { Metadata } from '@redwoodjs/web'

import FeatureDashboardCell from 'src/components/FeatureDashboardCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const FeatureDashboardPage = () => {
  return (
    <>
      <Metadata title="FeatureDashboard" description="FeatureDashboard page" />
      <PageHeader
        title={'Feature Management'}
        parentDataTestId="feature-management-page-header"
      />
      <div className="flex flex-1 justify-center h-[calc(100vh-5rem)]">
        <div className="px-8 pt-8 overflow-y-auto w-full h-full mb-2">
          <FeatureDashboardCell />
        </div>
      </div>
    </>
  )
}

export default FeatureDashboardPage
