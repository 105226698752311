import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { ChartBarIcon } from '@heroicons/react/24/outline'
import { MenuItem, Tooltip, Grid2 as Grid } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import {
  CreateClientFeature,
  CreateClientFeatureVariables,
  LimitBehaviour,
  UpdateClientFeature,
  UpdateClientFeatureVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  Client,
  ClientFeature,
  Feature,
} from 'src/components/FeatureDashboardCell/FeatureDashboardCell'
import IconButton from 'src/components/Library/IconButton'
import Switch from 'src/components/Library/Switch/Switch'
import Modal from 'src/components/Modal/Modal'
import {
  CREATE_CLIENT_FEATURE_MUTATION,
  UPDATE_CLIENT_FEATURE_MUTATION,
} from 'src/lib/queries/Feature/ClientFeature'

interface FeatureScriptOverrideModalProps {
  feature: Feature
  clients: Client[]
  overrideModalVisible: boolean
  setOverrideModalVisible: Dispatch<SetStateAction<boolean>>
  clientsWithFeature: ClientFeature[]
}

interface ButtonProps {
  onClick: () => void
  disabled?: boolean
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className={'border-b px-2 py-2'}>
      <GridToolbarQuickFilter
        variant={'outlined'}
        size={'small'}
        className={'h-full w-full'}
      />
    </GridToolbarContainer>
  )
}

const FeatureScriptOverrideModal: FC<FeatureScriptOverrideModalProps> = ({
  feature,
  clients,
  setOverrideModalVisible,
  overrideModalVisible,
  clientsWithFeature,
}) => {
  const [tempClientsWithFeature, setTempClientsWithFeature] = useState([])
  const [tempClientWithFeatureEnabled, setTempClientWithFeatureEnabled] =
    useState<number[]>(null)
  const [loading, setLoading] = useState(false)
  const [clientsData, setClientsData] = useState(clients)
  const [limit, setLimit] = useState(0)
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [QuotaBehaviour, setQuotaBehaviour] =
    useState<LimitBehaviour>('DISABLE')
  const [selectedClientFeature, setSelectedClientFeature] =
    useState<ClientFeature | null>(null)
  const [clientCurrentUsage, setClientCurrentUsage] = useState(0)
  const [clientQuotaModalVisible, setClientQuotaModalVisible] = useState(false)

  useEffect(() => {
    if (!feature) return
    setTempClientsWithFeature(
      clientsWithFeature
        .filter((clientFeature) => clientFeature?.featureId === feature?.id)
        .map((clientFeature) => clientFeature?.clientId),
    )

    const clientsWithFeatureEnabled = clientsWithFeature
      .filter(
        (clientFeature) =>
          clientFeature?.featureId === feature?.id && clientFeature?.enabled,
      )
      .map((clientFeature) => clientFeature?.clientId)

    setTempClientWithFeatureEnabled(clientsWithFeatureEnabled)
    setClientsData(
      clients.map((client) => {
        return {
          ...client,
          featureEnabled: clientsWithFeatureEnabled.includes(client.id),
        }
      }),
    )
  }, [feature, clientsWithFeature])

  useEffect(() => {
    if (selectedClient) {
      const clientFeature = clientsWithFeature.find(
        (clientFeature) =>
          clientFeature.clientId === selectedClient.id &&
          clientFeature.featureId === feature.id,
      )
      if (clientFeature) {
        setLimit(clientFeature.limit)
        setQuotaBehaviour(clientFeature.behaviour)
        setClientCurrentUsage(clientFeature.usage)
        setSelectedClientFeature(clientFeature)
        setClientQuotaModalVisible(true)
      } else {
        toast.error('Error finding client feature')
      }
    }
  }, [selectedClient])

  const handleQuotaBehaviourChange = (event: SelectChangeEvent) => {
    setQuotaBehaviour(event.target.value as LimitBehaviour)
  }

  const handleSaveQuota = () => {
    setLoading(true)
    updateClientFeature({
      variables: {
        id: selectedClientFeature.id,
        input: {
          limit,
          behaviour: QuotaBehaviour,
          usage: clientCurrentUsage,
        },
      },
    }).then(() => {
      handleResetQuotaModal()
    })
  }

  const handleResetQuotaModal = () => {
    if (loading) return
    setLimit(0)
    setQuotaBehaviour('DISABLE')
    setSelectedClient(null)
    setSelectedClientFeature(null)
    setClientCurrentUsage(null)
    setClientQuotaModalVisible(false)
  }

  const [createClientFeature] = useMutation<
    CreateClientFeature,
    CreateClientFeatureVariables
  >(CREATE_CLIENT_FEATURE_MUTATION, {
    onCompleted: () => {
      setLoading(false)
      toast.success('Feature added to client')
    },
    onError: () => {
      setLoading(false)
      toast.error('Error adding feature to client')
    },
    refetchQueries: [
      'FindFeatureDashboardQuery',
      'FindClientFeatureListQuery',
      'GetFeatures',
    ],
    awaitRefetchQueries: true,
  })

  const [updateClientFeature] = useMutation<
    UpdateClientFeature,
    UpdateClientFeatureVariables
  >(UPDATE_CLIENT_FEATURE_MUTATION, {
    onCompleted: () => {
      setLoading(false)
      toast.success('Feature updated')
    },
    onError: () => {
      setLoading(false)
      toast.error('Error updating feature')
    },
    refetchQueries: [
      'FindFeatureDashboardQuery',
      'FindClientFeatureListQuery',
      'GetFeatures',
    ],
    awaitRefetchQueries: true,
  })

  const onToggleClientFeature = (client: Client, enabled: boolean) => {
    setLoading(true)
    if (tempClientsWithFeature.includes(client.id)) {
      const clientFeature = clientsWithFeature.find(
        (clientFeature) =>
          clientFeature.clientId === client.id &&
          clientFeature.featureId === feature.id,
      )

      if (clientFeature) {
        updateClientFeature({
          variables: {
            id: clientFeature.id,
            input: {
              enabled,
            },
          },
        })
      } else {
        toast.error('Error updating feature, no client feature found')
      }
    } else {
      createClientFeature({
        variables: {
          input: {
            clientId: client.id,
            featureId: feature.id,
            enabled: enabled,
            usage: 0,
            limit: 0,
            behaviour: 'DISABLE',
            period: 'MONTHLY',
          },
        },
      })
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'client',
      headerName: 'Client',
      sortable: true,
      minWidth: 125,
      flex: 1,
      valueGetter: (_value, row) => {
        return row.name
      },
      renderCell: ({ row }) => {
        return (
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <Avatar alt={row.name} src={row.logomarkUrl} />
            <p className={'text-gray-800'}>{row.name}</p>
          </Stack>
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      sortable: false,
      renderCell: ({ id, row }) => (
        <Tooltip title={'Manage Quota'} key={id + 'quota'}>
          <div>
            <IconButton
              disabled={!row.featureEnabled}
              onClick={() => {
                setSelectedClient(row)
                setClientQuotaModalVisible(true)
              }}
            >
              <ChartBarIcon className={'h-5 w-5 stroke-2'} />
            </IconButton>
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'enabled',
      sortable: true,
      valueGetter: (_value, row) => row.featureEnabled,
      renderCell: ({ row }) => (
        <Switch
          checked={row.featureEnabled}
          onChange={() => {
            onToggleClientFeature(row, !row.featureEnabled)
          }}
        />
      ),
    },
  ]

  return (
    <>
      <Modal
        title={`Manage Clients - ${feature?.name}`}
        open={overrideModalVisible}
        onClose={() => setOverrideModalVisible(false)}
        className={'overflow-clip'}
        childClassName={'p-6 bg-gray-100 max-h-1/3 overflow-hidden'}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={3}
          className={'max-h-[500px] overflow-y-auto'}
        >
          <Grid size={{ xs: 12 }}>
            <div className={'h-[400px]'}>
              <DataGrid
                loading={loading}
                sx={{
                  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus':
                    {
                      outline: 'none',
                    },
                }}
                className={'bg-white'}
                slots={{
                  toolbar: CustomToolbar,
                }}
                columns={columns}
                rows={clientsData}
              />
            </div>
          </Grid>
        </Grid>
        <Modal
          title={`Manage ${selectedClient?.name} Quota`}
          open={clientQuotaModalVisible}
          dialogClassName={'max-h-1/3 max-w-sm overflow-hidden'}
          childClassName={'p-6 bg-gray-100 max-h-1/3 overflow-hidden'}
          footerVisible={true}
          confirmText={'Save'}
          loading={loading}
          onClose={() => handleResetQuotaModal()}
          onConfirm={() => handleSaveQuota()}
          onCancel={() => handleResetQuotaModal()}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={3}
            className={'max-h-[500px] overflow-y-auto'}
          >
            <Grid size={{ xs: 12 }}>
              <p className={'text-xs text-gray-500'}>Current Usage</p>
              <TextField
                placeholder={'Enter Current Usage'}
                type={'number'}
                fullWidth
                value={clientCurrentUsage}
                onChange={(e) =>
                  setClientCurrentUsage(parseInt(e.target.value))
                }
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <p className={'text-xs text-gray-500'}>Quota Limit</p>
              <TextField
                placeholder={'Enter Limit'}
                type={'number'}
                fullWidth
                value={limit}
                onChange={(e) => setLimit(parseInt(e.target.value))}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <p className={'text-xs text-gray-500'}>Behaviour</p>
              <Select
                placeholder={'Select Behaviour'}
                value={QuotaBehaviour}
                onChange={handleQuotaBehaviourChange}
                fullWidth
              >
                <MenuItem value={'WARNING'}>WARNING</MenuItem>
                <MenuItem value={'DISABLE'}>DISABLE</MenuItem>
                <MenuItem value={'SILENT'}>SILENT</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Modal>
      </Modal>
    </>
  )
}

export default FeatureScriptOverrideModal
