import { useEffect, useState } from 'react'

import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  CreateProductFeature,
  CreateProductFeatureVariables,
  DeleteProductFeature,
  DeleteProductFeatureVariables,
  UpdateProductFeature,
  UpdateProductFeatureVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { default as EmptyComponent } from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Switch from 'src/components/Library/Switch/Switch'
import Modal from 'src/components/Modal/Modal'
import {
  CREATE_PRODUCT_FEATURE,
  DELETE_PRODUCT_FEATURE,
  UPDATE_PRODUCT_FEATURE,
} from 'src/lib/queries/ProductFeatures/ProductFeatures'

export const QUERY = gql`
  query FindLinkProductToFeatureQuery {
    products: productFeaturesBoard {
      id
      name
    }
    linkedProducts: productFeatures {
      id
      stripeProductId
      featureId
      usagePerSeat
      feature {
        id
        name
      }
    }
    features {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <EmptyComponent />

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

const steps = ['Select Product', 'Link Features']

const ProductColumns = [{ field: 'name', headerName: 'Product', flex: 1 }]

export const Success = ({
  products,
  linkedProducts,
  isOpen,
  close,
  features,
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [linkedFeaturesToSelectedProduct, setLinkedFeaturesToSelectedProduct] =
    useState([])
  const [loading, setLoading] = useState(false)
  const [featuresList, setFeaturesList] = useState([])

  const [createProductFeature] = useMutation<
    CreateProductFeature,
    CreateProductFeatureVariables
  >(CREATE_PRODUCT_FEATURE, {
    onCompleted: () => {
      toast.success('Product linked to feature')
      setLoading(false)
    },
    onError: (error) => {
      toast.error(error.message)
      setLoading(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindLinkProductToFeatureQuery'],
  })

  const [updateProductFeature] = useMutation<
    UpdateProductFeature,
    UpdateProductFeatureVariables
  >(UPDATE_PRODUCT_FEATURE, {
    onCompleted: () => {
      toast.success('Product linked to feature')
      setLoading(false)
    },
    onError: (error) => {
      toast.error(error.message)
      setLoading(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindLinkProductToFeatureQuery'],
  })

  const [deleteProductFeature] = useMutation<
    DeleteProductFeature,
    DeleteProductFeatureVariables
  >(DELETE_PRODUCT_FEATURE, {
    onCompleted: () => {
      toast.success('Product unlinked from feature')
      setLoading(false)
    },
    onError: (error) => {
      toast.error(error.message)
      setLoading(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindLinkProductToFeatureQuery'],
  })

  useEffect(() => {
    if (selectedProduct) {
      const tempLinkedFeaturesToSelectedProduct = linkedProducts.filter(
        (linked) => linked.stripeProductId === selectedProduct.id,
      )
      setLinkedFeaturesToSelectedProduct(tempLinkedFeaturesToSelectedProduct)

      setFeaturesList(
        features.map((feature) => {
          const linked = tempLinkedFeaturesToSelectedProduct.find(
            (linked) => linked.featureId === feature.id,
          )
          return {
            ...feature,
            usagePerSeat: linked?.usagePerSeat || 0,
          }
        }),
      )
    }
  }, [features, linkedProducts, selectedProduct])

  const LinkedFeaturesColumns: GridColDef[] = [
    { field: 'name', headerName: 'Feature', flex: 1 },
    {
      field: 'usagePerSeat',
      headerName: 'Usage Per Seat',
      flex: 1,
      editable: true,
      type: 'number',
    },
    {
      field: 'Connect',
      headerName: 'Connect',
      align: 'right',
      valueGetter: (_value, row) => {
        return linkedFeaturesToSelectedProduct.some(
          (linked) => linked.featureId === row.id,
        )
      },
      renderCell: (params) => {
        return (
          <Switch
            checked={params.value}
            onClick={() => {
              if (params.value) {
                handleDeleteProductFeature(params.row.id)
              } else {
                handleCreateProductFeature(params.row.id)
              }
            }}
          />
        )
      },
    },
  ]

  const handleClose = () => {
    close()
  }

  const handleCreateProductFeature = (featureId: number) => {
    setLoading(true)
    createProductFeature({
      variables: {
        input: {
          featureId,
          stripeProductId: selectedProduct.id,
          usagePerSeat: 0,
        },
      },
    })
  }

  const handleUpdateProductFeature = (
    featureId: string,
    usagePerSeat: number,
  ) => {
    setLoading(true)
    const linked = linkedFeaturesToSelectedProduct.find(
      (linked) => linked.featureId === featureId,
    )
    updateProductFeature({
      variables: {
        id: linked.id,
        input: {
          usagePerSeat,
        },
      },
    })
  }

  const handleDeleteProductFeature = (featureId: string) => {
    setLoading(true)
    const linked = linkedFeaturesToSelectedProduct.find(
      (linked) => linked.featureId === featureId,
    )
    deleteProductFeature({
      variables: {
        id: linked.id,
      },
    })
  }

  const handleConfirm = () => {
    if (activeStep === steps.length - 1) {
      close()
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleCancel = () => {
    if (activeStep === 0) {
      close()
    } else {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      footerVisible
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmText={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
      cancelText={'Back'}
      title={'Connect Product to Feature'}
      dialogClassName={'max-w-4xl'}
      loading={loading}
      childClassName={'p-4'}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              <>{label}</>
              {selectedProduct && index === 0 && ': ' + selectedProduct.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <DataGrid
          columns={ProductColumns}
          rows={products}
          className={'mt-2 h-96'}
          onRowClick={({ row }) => setSelectedProduct(row)}
          onRowDoubleClick={({ row }) => {
            setSelectedProduct(row)
            setActiveStep(1)
          }}
        />
      )}
      {activeStep === 1 && (
        <DataGrid
          columns={LinkedFeaturesColumns}
          rows={featuresList}
          className={'mt-2 h-96'}
          loading={loading}
          processRowUpdate={(newRow) => {
            handleUpdateProductFeature(newRow.id, newRow.usagePerSeat)
            return newRow
          }}
          isCellEditable={(params) => {
            const isLinked = linkedFeaturesToSelectedProduct.some(
              (linked) => linked.featureId === params.row.id,
            )
            return isLinked
          }}
        />
      )}
    </Modal>
  )
}
