export const UPDATE_PRODUCT_FEATURES_LIST = gql`
  mutation UpdateProductFeaturesList(
    $activeFeatures: [Int!]!
    $stripeProductId: String!
  ) {
    updateProductFeaturesList(
      activeFeatures: $activeFeatures
      stripeProductId: $stripeProductId
    )
  }
`

export const UPDATE_PRODUCT_FEATURE = gql`
  mutation UpdateProductFeature($id: Int!, $input: UpdateProductFeatureInput!) {
    updateProductFeature(id: $id, input: $input) {
      id
      feature {
        id
        name
      }
      usagePerSeat
    }
  }
`

export const CREATE_PRODUCT_FEATURE = gql`
  mutation CreateProductFeature($input: CreateProductFeatureInput!) {
    createProductFeature(input: $input) {
      id
      feature {
        id
        name
      }
      usagePerSeat
    }
  }
`

export const DELETE_PRODUCT_FEATURE = gql`
  mutation DeleteProductFeature($id: Int!) {
    deleteProductFeature(id: $id) {
      id
    }
  }
`
