import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { CreateFeature, CreateFeatureVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import Modal from 'src/components/Modal/Modal'
import { CREATE_FEATURE_MUTATION } from 'src/lib/queries/Feature/Feature'
import { updateMap } from 'src/Util'

export interface AddFeatureModalProps {
  featureModalVisible: boolean
  setFeatureModalVisible: Dispatch<SetStateAction<boolean>>
}

const AddFeatureModal: FC<AddFeatureModalProps> = ({
  featureModalVisible,
  setFeatureModalVisible,
}) => {
  const [createFeature] = useMutation<CreateFeature, CreateFeatureVariables>(
    CREATE_FEATURE_MUTATION,
    {
      refetchQueries: ['FindFeatureDashboardQuery'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast.success('Feature Created')
        setLoading(false)
        setFeatureModalVisible(false)
      },
      onError: () => {
        toast.error('Error creating feature')
        setLoading(false)
      },
    },
  )
  useEffect(() => {
    setFormData(
      new Map([
        ['Name', ''],
        ['Description', ''],
      ]),
    )
  }, [featureModalVisible])

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(
    new Map([
      ['Name', ''],
      ['Description', ''],
    ]),
  )

  return (
    <Modal
      onClose={() => {
        setFeatureModalVisible(false)
        setFormData(
          new Map([
            ['Name', ''],
            ['Description', ''],
          ]),
        )
      }}
      open={featureModalVisible}
      childClassName={'p-6'}
      title={'Add Feature'}
      loading={loading}
      footerVisible
      onCancel={() => setFeatureModalVisible(false)}
      onConfirm={() => {
        if (formData.get('Name') !== '' || formData.get('Description') !== '') {
          setLoading(true)
          createFeature({
            variables: {
              input: {
                name: formData.get('Name'),
                description: formData.get('Description'),
                enabled: false,
              },
            },
          })
        } else {
          toast.error('Please fill out all fields')
        }
      }}
    >
      <SimpleForm
        records={formData}
        handleChange={(key, value) =>
          updateMap(key, value, setFormData, formData)
        }
      />
    </Modal>
  )
}

export default AddFeatureModal
